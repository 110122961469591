.App {
  height: 100vh;
  display: flex;
  background: var(--White, #fff);
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.app1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;

  background: #f4f4f4;
}
