body {
  margin: 0;
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  font-size: 16px;
  background: #f3f4f6;
}

code {
  font-family: 'Jost', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  text-decoration: none;
}
